/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import store from '../../redux/store';
import {
  errorMessageInvalidChar,
  errorMessageInvalidCharacters,
  errorMessageMaxFiledLength,
  patternForLatinCharacters,
  patternForLatinCharactersForModelName,
  patternForLatinCharactersForNFC,
  patternForLatinCharactersWithoutSpaces,
  patternUrl,
  requiredField,
  urlErrorMessage,
} from './constants';
import { SupportedApps } from './../../types/device';

const getValidationFieldsFirmwaresPublish = (
  isDeviceSensorSpecificationRequired?: boolean,
) => ({
  firmwareVersion: yup
    .string()
    .required(requiredField)
    .max(35, 'Max field length is 35 characters'),
  releaseDate: yup.date().required(requiredField),
  atVersionId: yup.string().min(0).required(requiredField),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sensorDataVersionId: yup.string().when((...args) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, schema, form] = args;
    if (
      isDeviceSensorSpecificationRequired ||
      form?.from?.[1]?.value?.isSensorSpecificationRequired
    ) {
      return yup.string().min(0).required(requiredField);
    }
    return yup.string().notRequired();
  }),
  addedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;
    return !value.some((item) => item.length > 128);
  }),
  changedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;
    return !value.some((item) => item.length > 128);
  }),
  fixedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;
    return !value.some((item) => item.length > 128);
  }),
  notSupportedCommands: yup.string().matches(/^AT\+/),
  firmwareFile: yup
    .mixed()
    .required(requiredField)
    .test('fileSize', 'Max file size allowed 2MB', (value) => {
      if (!value) return true;
      return value.size <= 2000000;
    })
    .test('fileType', 'Only zip files allow', (value) => {
      if (!value) return true;
      return (
        value.type === 'application/zip' ||
        value.type === 'application/x-zip-compressed'
      );
    }),
});

export const getValidationShemaFirmwares = (
  isSensorSpecificationRequired?: boolean,
): yup.ObjectSchema<any> =>
  yup
    .object()
    .shape(getValidationFieldsFirmwaresPublish(isSensorSpecificationRequired));

function requiredIfNotFileLocation(this: any, value: File) {
  if (this.parent.location || value) return true;
  return false;
}

export const getValidationShemaFirmwaresEdit = (
  isSensorSpecificationRequired?: boolean,
): yup.ObjectSchema<any> =>
  yup.object().shape({
    ...getValidationFieldsFirmwaresPublish(isSensorSpecificationRequired),
    firmwareFile: yup
      .mixed()
      .test(
        'required',
        'Firmware file is a required field',
        requiredIfNotFileLocation,
      )
      .test('fileSize', 'Max file size allowed 2MB', (value) => {
        if (!value) return true;
        return value.size <= 2000000;
      })
      .test('fileType', 'Only zip files allow', (value) => {
        if (!value || yup.ref('location')) return true;
        return (
          value.type === 'application/zip' ||
          value.type === 'application/x-zip-compressed'
        );
      }),
  });

export const validationSchemaFirmwaresDraft = yup.object().shape({
  firmwareVersion: yup.string().max(35, 'Max field length is 35 characters'),
  releaseDate: yup.date(),
  atVersion: yup.string().min(0),
  addedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;
    return !value.some((item) => item.length > 128);
  }),
  changedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;
    return !value.some((item) => item.length > 128);
  }),
  fixedDescription: yup.array().test('length', '', (value) => {
    if (!value) return true;

    return !value.some((item) => item.length > 128);
  }),
  notSupportedCommands: yup.string().matches(/^AT\+/, errorMessageInvalidChar),
  firmwareFile: yup
    .mixed()
    .test(
      'fileSize',
      'Firmware file is too large. Your file should be up to 2 MB in size. Please upload different file',
      (value) => {
        if (!value) return true;
        return value.size <= 2000000;
      },
    )
    .test(
      'fileType',
      'File is not supported. Please, upload file with ZIP format',
      (value) => {
        if (!value) return true;
        return (
          value.type === 'application/zip' ||
          value.type === 'application/x-zip-compressed'
        );
      },
    ),
});

export const validationShemaDeviceDetails: any = {
  modelId: yup
    .string()
    .required(requiredField)
    .max(25, 'Max field length is 25 characters')
    .test('modelId', 'ModelId already exist!', (item) =>
      item !== undefined
        ? !store.getState().devices.modelIds.includes(item.toLowerCase())
        : true,
    ),
  modelName: yup
    .string()
    .required(requiredField)
    .max(128, 'Max field length is 128 characters')
    .matches(patternForLatinCharactersForModelName, errorMessageInvalidChar),
  hardwareModelName: yup
    .string()
    .max(128, 'Max field length is 128 characters')
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    )
    .required(requiredField),
  productModelName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    ),
  productDescription: yup
    .string()
    .url(errorMessageInvalidChar)
    .required(requiredField)
    .matches(patternUrl, urlErrorMessage),
  deviceDocumentation: yup
    .string()
    .url(errorMessageInvalidChar)
    .required(requiredField)
    .matches(patternUrl, urlErrorMessage),
  deviceInfo: yup
    .string()
    .required(requiredField)
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .max(1024, errorMessageMaxFiledLength),
  createdAt: yup.date(),
  supportedApps: yup
    .array(yup.mixed<SupportedApps>())
    .min(1, 'Select at least one platform')
    .required(),
  mode: yup.string(),
  nfcActivationValue: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  bleName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  image: yup
    .mixed()
    .required(requiredField)
    .test(
      'fileType',
      'File is not supported. Only svg or png files',
      (value) => {
        if (!value) return true;
        return ['image/png', 'image/svg'].includes(value.type);
      },
    ),
};

const validationSchemaEditDevice = yup.object().shape({
  modelId: yup.string().notRequired(),
  modelName: yup
    .string()
    .required(requiredField)
    .max(128, 'Max field length is 128 characters')
    .matches(patternForLatinCharactersForModelName, errorMessageInvalidChar),
  hardwareModelName: yup
    .string()
    .max(128, 'Max field length is 128 characters')
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    )
    .required(requiredField),
  productModelName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .nullable()
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    ),
  productDescription: yup
    .string()
    .url(errorMessageInvalidChar)
    .required(requiredField)
    .matches(patternUrl, urlErrorMessage),
  deviceDocumentation: yup
    .string()
    .url(errorMessageInvalidChar)
    .required(requiredField)
    .matches(patternUrl, urlErrorMessage),
  deviceInfo: yup
    .string()
    .required(requiredField)
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .max(1024, errorMessageMaxFiledLength),
  createdAt: yup.date(),
  supportedApps: yup
    .array(yup.mixed<SupportedApps>())
    .min(1, 'Select at least one platform')
    .required(),
  mode: yup.string(),
  nfcActivationValue: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  bleName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  image: yup
    .mixed()
    .test(
      'fileType',
      'File is not supported. Only svg or png files',
      (value) => {
        if (!value) {
          return true;
        }
        if (typeof value === 'string') {
          return true;
        }
        return ['image/png', 'image/svg'].includes(value.type);
      },
    )
    .required(requiredField),
});

export const getValidationShemaForFirmwaresAndDeviceDetails = (
  isSensorSpecificationRequired?: boolean,
): yup.ObjectSchema<any> =>
  yup.object().shape({
    ...validationShemaDeviceDetails,
    firmware: getValidationShemaFirmwares(isSensorSpecificationRequired),
  });

const validationShemaDeviceDetailsDraft: any = {
  modelId: yup
    .string()
    .required(requiredField)
    .max(25, 'Max field length is 25 characters')
    .test('odelId', 'ModelId already exist!', (item) =>
      item !== undefined
        ? !store.getState().devices.modelIds.includes(item.toLowerCase())
        : true,
    ),
  modelName: yup
    .string()
    .max(128, 'Max field length is 128 characters')
    .matches(patternForLatinCharactersForModelName),
  hardwareModelName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    )
    .required(requiredField),
  productModelName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .matches(
      patternForLatinCharactersWithoutSpaces,
      errorMessageInvalidCharacters,
    ),
  productDescription: yup.string().url().matches(patternUrl, urlErrorMessage),
  deviceDocumentation: yup.string().url().matches(patternUrl, urlErrorMessage),
  deviceInfo: yup.string().matches(patternForLatinCharacters).max(1024),
  createdAt: yup.date(),
  mode: yup.string(),
  nfcActivationValue: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  bleName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .when('connectionType', {
      is: 'NFC',
      then: yup
        .string()
        .required(requiredField)
        .matches(
          patternForLatinCharactersForNFC,
          errorMessageInvalidCharacters,
        ),
    }),
  supportedApps: yup
    .array(yup.mixed<SupportedApps>())
    .min(1, 'Select at least one platform')
    .required(),
  image: yup
    .mixed()
    .test(
      'fileType',
      'File is not supported. Only svg or png files',
      (value) => {
        if (!value) return true;
        return ['image/png', 'image/svg'].includes(value.type);
      },
    ),
};
const validationShemaDeviceDetailsDraftEdit: any = yup.object().shape({
  modelId: yup.string().notRequired(),
  modelName: yup
    .string()
    .max(128, 'Max field length is 128 characters')
    .matches(patternForLatinCharactersForModelName),
  hardwareModelName: yup
    .string()
    .max(128, 'Max field length is 128 characters')
    .matches(patternForLatinCharacters)
    .required(requiredField),
  productDescription: yup.string().url().matches(patternUrl, urlErrorMessage),
  deviceDocumentation: yup.string().url().matches(patternUrl, urlErrorMessage),
  deviceInfo: yup.string().matches(patternForLatinCharacters).max(1024),
  createdAt: yup.date(),
  supportedApps: yup
    .array(yup.mixed<SupportedApps>())
    .min(1, 'Select at least one platform')
    .required(),
  mode: yup.string(),
  nfcActivationValue: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .matches(patternForLatinCharactersForNFC),
  bleName: yup
    .string()
    .max(25, 'Max field length is 25 characters')
    .matches(patternForLatinCharactersForNFC),
  image: yup
    .mixed()
    .test(
      'fileType',
      'File is not supported. Only svg or png files',
      (value) => {
        if (!value) {
          return true;
        }
        if (typeof value === 'string') {
          return true;
        }
        return ['image/png', 'image/svg'].includes(value.type);
      },
    ),
});

export const validationDeviceFirmwaresDraft = yup.object().shape(
  {
    ...validationShemaDeviceDetailsDraft,
    firmware: validationSchemaFirmwaresDraft,
  },
  [['hardwareModelName', 'productModelName']],
);

export const getValidationShema = (
  appointment:
    | 'firmwares'
    | 'device'
    | 'both'
    | 'firmwaresDraft'
    | 'firmwaresEdit'
    | 'deviceDraft'
    | 'deviceEdit'
    | 'deviceDraftEdit',
  isPublish: boolean | null,
  isSensorSpecificationRequired?: boolean,
): yup.InferType<
  | typeof validationShemaDeviceDetails
  | typeof getValidationShemaFirmwaresEdit
  | typeof getValidationShemaFirmwares
  | typeof getValidationShemaForFirmwaresAndDeviceDetails
  | typeof validationDeviceFirmwaresDraft
  | typeof validationSchemaFirmwaresDraft
  | typeof validationShemaDeviceDetailsDraft
  | typeof validationSchemaEditDevice
  | typeof validationShemaDeviceDetailsDraftEdit
> => {
  const appointments = {
    firmwaresEdit: isPublish
      ? getValidationShemaFirmwaresEdit(isSensorSpecificationRequired)
      : validationSchemaFirmwaresDraft,
    firmwares: getValidationShemaFirmwares(isSensorSpecificationRequired),
    device: yup.object().shape({ ...validationShemaDeviceDetails }),
    both: isPublish
      ? getValidationShemaForFirmwaresAndDeviceDetails(
          isSensorSpecificationRequired,
        )
      : validationDeviceFirmwaresDraft,
    firmwaresDraft: validationSchemaFirmwaresDraft,
    deviceDraft: yup.object().shape(validationShemaDeviceDetailsDraft),
    deviceEdit: validationSchemaEditDevice,
    deviceDraftEdit: validationShemaDeviceDetailsDraftEdit,
  };
  return appointments[appointment];
};
