export const errorMessageInvalidChar =
  'Invalid characters. Please check the input';
export const errorMessageMaxFiledLength = 'Max field length is 1024 characters';
export const requiredField = 'This is required field.';
export const urlErrorMessage = 'Invalid URL. Please make sure it’s RAKwireless';
export const errorMessageInvalidCharacters =
  'Invalid characters. Please check the input';

export const patternForLatinCharacters =
  /^$|[aA-zZ0-9!@#$%&*()_+-={}[\]|":;'/.,<>?\s]+$/;

export const patternForLatinCharactersForModelName =
  /^$|[aA-zZ0-9!@#$%&*()_+-={}[\]|":;'/.,<>?®\s]+$/;

export const patternForLatinCharactersWithoutSpaces =
  //eslint-disable-next-line
  /^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

export const patternForLatinCharactersForNFC = /^[a-zA-Z0-9_-]+$/;

export const urlPattern =
  /$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const patternUrl = new RegExp(
  '^https://docs.rakwireless.com|^https://store.rakwireless.com',
);
